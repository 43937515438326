import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, InputNumber, Modal, Space } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'

const WrapModalScore = styled.div`
  .modal-nft-title {
    color: #f6f8fa;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 20px;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 20px;
      line-height: 32px;
    }
  }

  .modal-nft-wrap-content {
    .btn-confirm {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 16px;

      button {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        height: 48px;
        width: 175px;
        box-shadow: 0px 0px 12px 0px rgba(191, 151, 255, 0.24) inset;
        border-radius: 34778.336px;
        border: 1px solid #6e9f96;
        background: rgba(0, 0, 0, 0.65);
        backdrop-filter: blur(1.7389342784881592px);

        &:nth-child(1) {
          background: transparent;
          backdrop-filter: none;
          width: 135px;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    .title-table {
      display: flex;
      justify-content: space-around;
      padding-right: 22px;
      margin-bottom: 10px;
    }
  }

  .ant-pagination {
    text-align: right;
  }

  .table-row {
    display: flex;
    justify-content: flex-end;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:last-child {
      margin-bottom: 15px;
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      padding: 4px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.2);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 5px;
      }

      p {
        font-size: 14px;
        margin-right: 4px;
      }

      span {
        color: #ffd100;
        font-size: 10px;
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.1);
        padding: 0px 3px;
      }
    }
  }

  .ant-input-number,
  .ant-picker {
    width: 100%;
  }

  input,
  .ant-select-selector {
    align-items: center;
    height: 50px !important;
  }

  .ant-picker {
    input {
      height: 40px !important;
    }
  }

  .exist-error {
    display: none;
    & .ant-form-item-control-input {
      display: none;
    }

    &.ant-form-item-has-error {
      display: block;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

const ModalScore = ({
  modal,
  setModal,
  teamId,
  onSubmitScore,
  disabled,
}: {
  modal: any
  setModal: (input: any) => void
  teamId?: string
  onSubmitScore: any
  disabled: boolean
}) => {
  const [form] = Form.useForm()

  const onCancel = useCallback(() => {
    setModal((val) => ({
      ...val,
      toggle: false,
    }))
    form.resetFields()
  }, [form, setModal])

  const onFinish = useCallback(
    async (values: any) => {
      if (teamId) await onSubmitScore(teamId, values.scores)

      setModal((val) => ({
        ...val,
        toggle: false,
        data: values,
      }))
    },
    [onSubmitScore, setModal, teamId],
  )

  const initialValues = useMemo(
    () => ({
      scores: modal?.data?.scores?.map(({ id, winRate, scoreA, scoreB }) => ({ id, winRate, scoreA, scoreB })),
    }),
    [modal?.data?.scores],
  )

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const validateExistScore =
    (name) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        const allScores = form.getFieldValue('scores')

        const conflict = allScores.filter(
          (item) =>
            item.scoreA === getFieldValue(['scores', name, 'scoreA']) &&
            item.scoreB === getFieldValue(['scores', name, 'scoreB']),
        )
        if (conflict?.length < 2) {
          return Promise.resolve()
        }
        return Promise.reject(new Error('Score already exists!'))
      },
    })

  return (
    <Modal title="" centered open={modal?.toggle} footer={false} onCancel={onCancel} destroyOnClose>
      <Form form={form} name="control-hooks" initialValues={initialValues} onFinish={onFinish} layout="vertical">
        <WrapModalScore>
          <div className="modal-nft-title">Score</div>

          <div className="modal-nft-wrap-content">
            <div className="modal-nft-content">
              <div className="title-table">
                <p>Win Rate</p>
                <p>Score A</p>
                <p>Score B</p>
              </div>

              <div className="table">
                <Form.List name="scores">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div style={{ marginBottom: 8 }}>
                          <Space key={key} style={{ display: 'flex' }} align="baseline">
                            <Form.Item
                              {...restField}
                              name={[name, 'winRate']}
                              rules={[{ required: true, message: 'Missing winRate' }]}
                            >
                              <InputNumber placeholder="winRate" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'scoreA']}
                              rules={[{ required: true, message: 'Missing scoreA' }]}
                            >
                              <InputNumber
                                placeholder="scoreA"
                                onChange={() => {
                                  form.validateFields([['scores', name, 'id']])
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'scoreB']}
                              rules={[{ required: true, message: 'Missing scoreB' }]}
                            >
                              <InputNumber
                                placeholder="scoreB"
                                onChange={() => {
                                  form.validateFields([['scores', name, 'id']])
                                }}
                              />
                            </Form.Item>
                            {!disabled && <MinusCircleOutlined onClick={() => remove(name)} rev={undefined} />}
                          </Space>
                          <Form.Item
                            {...restField}
                            className="exist-error"
                            name={[name, 'id']}
                            rules={[validateExistScore(name)]}
                          />
                        </div>
                      ))}
                      {!disabled && (
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined rev={undefined} />}>
                            Add Score
                          </Button>
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.List>
              </div>
            </div>

            {!disabled && (
              <div className="btn-confirm">
                <button type="button" onClick={onCancel}>
                  Cancel
                </button>
                <button type="submit">Save</button>
              </div>
            )}
          </div>
        </WrapModalScore>
      </Form>
    </Modal>
  )
}

export default ModalScore
